<template>
  <auth-bg>
    <div class="container h-100">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4 mx-auto">
          <div class="card py-4 px-3 rounded-5">
            <div class="card-header bg-transparent border-0 text-center h2 fw-bold text-uppercase">
              Reset Password
            </div>
            <div class="card-body">
              <ValidationObserver v-slot="{ invalid }" v-if="validToken && !loading">
                <form class="auth-form" @submit.prevent="submit">
                  <ValidationProvider v-slot="v"
                                      rules="required|pass|min:6|max:32"
                                      name="Password">
                    <text-input v-model="form.password" label="Create Password" placeholder="Password"
                                :type="passwordOpen ? 'text': 'password'"
                                :error="hasError('password', v.errors)"
                                :errors="getErrors('password', v.errors)"
                                autocomplete="new-password"
                    >
                      <template #append>
                        <button class="btn eye-btn" type="button" @click="passwordOpen = !passwordOpen">
                          <i class="far" :class="{'fa-eye': !passwordOpen, 'fa-eye-slash':passwordOpen}"></i>
                        </button>
                      </template>
                    </text-input>
                  </ValidationProvider>
                  <ValidationProvider v-slot="v"
                                      :rules="{required: true, confirm: form.password,}"
                                      name="Confirm Password">
                    <text-input v-model="form.password_confirmation" label="Confirm Password" placeholder="Password"
                                :type="passwordOpen ? 'text': 'password'"
                                :error="hasError('password_confirmation', v.errors)"
                                :errors="getErrors('password_confirmation', v.errors)"
                                autocomplete="new-password"
                    >
                      <template #append>
                        <button class="btn eye-btn" type="button" @click="passwordOpen = !passwordOpen">
                          <i class="far" :class="{'fa-eye': !passwordOpen, 'fa-eye-slash':passwordOpen}"></i>
                        </button>
                      </template>
                    </text-input>
                  </ValidationProvider>
                  <div class="flex">
                    <a class="btn btn-outline-primary d-block mx-auto w-75 rounded-pill text-uppercase mt-4 mr-3"
                       :href="'spinninginstructor://resetpassword/'+form.email+ '/'+form.token">
                      Use Application
                    </a>
                    <button class="btn btn-primary d-block mx-auto w-75 rounded-pill text-uppercase mt-4" type="submit"
                            :disabled="invalid">
                      Confirm
                    </button>
                  </div>
                </form>
              </ValidationObserver>
              <div v-else-if="!validToken && !loading" class="text-center">
                Invalid Token
              </div>
              <div v-else class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </auth-bg>
</template>

<script>
import TextInput from "@/components/form/TextInput";
import hasApiValidation from "@/mixins/hasApiValidation";
import AuthBg from "@/components/AuthBg";

export default {
  name: "ResetPassword",
  components: {AuthBg, TextInput},
  mixins: [hasApiValidation],
  props: ['token', 'email'],
  data() {
    return {
      form: {
        token: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      passwordOpen: false,
      loading: true,
      validToken: false,
    }
  },
  mounted() {
    this.form.token = this.token ?? '';
    this.form.email = this.email ?? '';
    this.checkToken();
  },
  methods: {
    checkToken() {
      this.$http.get('/auth/reset-password', {
        params: {
          token: this.form.token,
          email: this.form.email
        }
      })
          .then(() => {
            this.validToken = true;
          })
          .finally(() => {
            this.loading = false;
          })
    },
    submit() {
      this.setErrors({});
      this.$http.post('/auth/reset-password', this.form)
          .then(({data}) => {
            this.$noty.success(data.message);
            this.setErrors({});
            this.$router.push({name: 'Login'});
          })
          .catch(({response}) => {
            this.$noty.error(response.data.message);
            this.setErrors(response.data.errors);
          });
    },
    login() {
      this.$router.push({name: 'Login'});
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <auth-bg>
    <div class="container h-100">
      <div class="row h-100 align-items-center justify-content-center">
        <div class="col-md-12 col-lg-10 col-xl-8 mx-auto" v-if="!submitted">
          <div class="card py-4 px-3 rounded-5">
            <div class="card-header bg-transparent border-0 text-center h2 fw-bold text-uppercase">
              Sign up
            </div>
            <div class="card-body">

              <ValidationObserver v-slot="{ invalid }">
                <form class="auth-form" @submit.prevent="submit">
                  <div class="row">
                    <div class="col-lg-6">
                      <ValidationProvider v-slot="v" rules="required|max:255" name="First Name">
                        <text-input v-model="form.first_name" label="First Name" placeholder="First Name" type="text"
                                    :error="hasError('first_name', v.errors)"
                                    :errors="getErrors('first_name', v.errors)"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-6">
                      <ValidationProvider v-slot="v" rules="required|max:255">
                        <text-input v-model="form.last_name" label="Last Name" placeholder="Last Name" type="text"
                                    :error="hasError('last_name', v.errors)"
                                    :errors="getErrors('last_name', v.errors)"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-6">
                      <ValidationProvider v-slot="v" rules="required|email" name="Email">
                        <text-input v-model="form.email" label="Email" placeholder="Email" type="email"
                                    :error="hasError('email', v.errors)"
                                    :errors="getErrors('email', v.errors)"
                        >

                          <template #append>
                            <span class="eye-btn"><i class="far fa-envelope"></i></span>
                          </template>
                        </text-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-6">
                      <ValidationProvider v-slot="v" rules="required">

                        <vc-date-picker v-model="form.birthdate" :masks="{input: 'MM/DD/YYYY'}"
                                        :popover="{placement: 'bottom'}"
                                        :max-date='subtractYears(10)'
                                        :min-date='subtractYears(100)'
                                        :timezone="'UTC'"
                                        :attributes='calendar_attrs'
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <text-input :value="inputValue"
                                        v-on="inputEvents" label="Birthdate" placeholder="Birthdate" required
                                        :error="hasError('birthdate', v.errors)"
                                        :errors="getErrors('birthdate', v.errors)"></text-input>
                          </template>
                        </vc-date-picker>
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-6">
                      <ValidationProvider v-slot="v" rules="required">
                        <form-group :error="hasError('sex', v.errors)"
                                    :errors="getErrors('sex', v.errors)"
                        >
                          <template #label>
                            <slot name="label">
                              <label class="fw-medium d-flex ">
                                <span>Sex</span><span class="px-2">*</span>
                              </label>
                            </slot>
                          </template>
                          <select v-model="form.sex" class="form-control">
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </form-group>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider v-slot="v" rules="required">
                        <text-input v-model="form.ftp" label="FTP" placeholder="FTP" required type="number" min="1"
                                    :error="hasError('ftp', v.errors)"
                                    :errors="getErrors('ftp', v.errors)"></text-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-6">
                      <ValidationProvider v-slot="v" rules="required">
                        <text-input v-model.number="form.weight" label="Weight" placeholder="Weight" required type="number"
                                    min="1"
                                    :error="hasError('weight', v.errors)"
                                    :errors="getErrors('weight', v.errors)">
                          <template #append>
                            <select v-model="form.weight_dim"
                                    @change="changeDim($event, 'weight_dim')"
                                    class="append_select">
                              <option value="pound">LBS</option>
                              <option value="kg">KG</option>
                            </select>
                          </template>
                        </text-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-6">
                      <ValidationProvider v-slot="v" rules="required">
                        <text-input v-model.number="form.height" label="Height" placeholder="Height" required type="number"
                                    min="1"
                                    :error="hasError('height', v.errors)"
                                    :errors="getErrors('height', v.errors)">
                          <template #append>
                            <select v-model="form.height_dim"
                                    @change="changeDim($event, 'height_dim')"
                                    class="append_select">
                              <option value="in">IN</option>
                              <option value="cm">CM</option>
                            </select>
                          </template>
                        </text-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-6">
                      <ValidationProvider v-slot="v"
                                          rules="required|pass|min:6|max:32"
                                          name="Password">
                        <text-input v-model="form.password" label="Password" placeholder="Password"
                                    :type="passwordOpen ? 'text': 'password'"
                                    :error="hasError('password', v.errors)"
                                    :errors="getErrors('password', v.errors)"
                        >
                          <template #append>
                            <button class="btn eye-btn" type="button" @click="passwordOpen = !passwordOpen">
                              <i class="far" :class="{'fa-eye': !passwordOpen, 'fa-eye-slash':passwordOpen}"></i>
                            </button>
                          </template>
                        </text-input>
                      </ValidationProvider>
                    </div>
                    <div class="col-lg-6">
                      <ValidationProvider v-slot="v"
                                          :rules="{required: true, confirm: form.password,}"
                                          name="Password">
                        <text-input v-model="form.password_confirmation" label="Confirm Password"
                                    placeholder="Confirm Password"
                                    :error="hasError('password_confirmation', v.errors)"
                                    :errors="getErrors('password_confirmation', v.errors)"
                                    :type="passwordOpen ? 'text': 'password'"
                        >
                          <template #append>
                            <button class="btn eye-btn" type="button" @click="passwordOpen = !passwordOpen">
                              <i class="far" :class="{'fa-eye': !passwordOpen, 'fa-eye-slash':passwordOpen}"></i>
                            </button>
                          </template>
                        </text-input>
                      </ValidationProvider>
                    </div>
                  </div>
                  <button class="btn btn-primary d-block mx-auto w-75 rounded-pill text-uppercase" type="submit"
                          :disabled="invalid">
                    Sign Up
                  </button>
                  <a @click.prevent="login" class="text-body text-center d-block mt-4 text-decoration-none" href="#">
                    Already have an account? <span class="text-primary text-decoration-underline">Log in</span></a>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
        <div class="col-md-5 mx-auto" v-else>
          <div class="card py-5 px-3">
            <div class="card-header bg-transparent border-0 text-center h2 fw-bold text-uppercase">
              Please check your email
            </div>
            <div class="card-body text-center">
              <span>Your instructor profile has been submitted.</span><br>
              <span>We will approve your profile and be in contact shortly.</span><br>
              <button class="btn btn-primary d-block mx-auto w-75 rounded-pill text-uppercase mt-5"
                      @click.prevent="login">Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </auth-bg>
</template>

<script>
import TextInput from "@/components/form/TextInput";
import hasApiValidation from "@/mixins/hasApiValidation";
import AuthBg from "@/components/AuthBg";
import FormGroup from "@/components/form/FormGroup";

export default {
  name: "Register",
  components: {FormGroup, AuthBg, TextInput},
  mixins: [hasApiValidation],
  data() {
    return {
      submitted: false,
      calendar_attrs: [
        {
          key: 'today',
          highlight: true,
          dates: new Date(),
        },
      ],
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        birthdate: null,
        weight: null,
        weight_dim: 'pound',
        height: null,
        height_dim: 'in',
        ftp: 125,
        sex: 'male',
      },
      passwordOpen: false,
    }
  },
  methods: {
    submit() {
      this.setErrors({});
      let data = Object.assign({}, this.form);
      data.birthdate = this.$moment(data.birthdate).utc().format('YYYY-MM-DD')
      this.$http.post('/auth/register', data)
        .then(() => {
          this.submitted = true;
          this.setErrors({});
        })
        .catch(({response}) => {
          this.$noty.error(response.data.message);
          this.setErrors(response.data.errors);
        });
    },
    forgot() {
      this.$router.push({name: 'ForgotPassword'});
    },

    changeDim(e, dim) {
      const val = e.target.value;
      if (dim === 'height_dim') {
        if (val === 'cm') {
          this.form.weight_dim = 'kg';
        } else {
          this.form.weight_dim = 'pound';
        }
      }

      if (dim === 'weight_dim') {
        if (val === 'kg') {
          this.form.height_dim = 'cm';
        } else {
          this.form.height_dim = 'in';
        }
      }
    },
    subtractYears(numOfYears, date = new Date()) {
      date.setFullYear(date.getFullYear() - numOfYears);
      return date;
    },

    login() {
      this.$router.push({name: 'Login'});
    }
  }
}
</script>

<style scoped>

</style>